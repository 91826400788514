import React, { useEffect, useState, useRef} from "react";
import { Link, useSearchParams, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import "./MainWindow.css";
import { TailSpin } from "react-loader-spinner";

export default function MainWindow(props) {
  const date = new Date();
  
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState(2)
  let [searchParams, setSearchParams] = useSearchParams();
  const [games, setName] = useState([]);
  let { genre_slug } = useParams();
  // const myRef = useRef();
  var apiLink;
  var heading;

  const state = useLocation();

  if (props.pageID === "new-and-trending") {
    apiLink =
      "https://api.rawg.io/api/games?key=5fcdb676e5d74141ac1bb36b70f5d949";
    heading = "New and trending";
  } else if (props.pageID === "top-250") {
    apiLink =
      "https://api.rawg.io/api/games?ordering=-metacritic&key=5fcdb676e5d74141ac1bb36b70f5d949";
    heading = "Top 250";
  } else if (props.pageID === "all-games") {
    apiLink =
      "https://api.rawg.io/api/games?key=5fcdb676e5d74141ac1bb36b70f5d949";
    heading = "All Games";
  } else if (props.pageID === "search") {
    apiLink =
      "https://api.rawg.io/api/games?search=" +
      searchParams.get("q") +
      "&key=5fcdb676e5d74141ac1bb36b70f5d949";
    heading = "Search Results for '" + searchParams.get("q") + "'";
  } else if (props.pageID === "genre-games") {
    apiLink =
      "https://api.rawg.io/api/games?genres=" + genre_slug + "&key=5fcdb676e5d74141ac1bb36b70f5d949";
    heading = genre_slug.charAt(0).toUpperCase() + genre_slug.slice(1) + " Games";
  }


  useEffect(() => {
    setLoading(true);
    props.setProgress(20);
    const loadData = async function () {
      const { data } = await axios.get(apiLink);

      setName(data.results);
      setLoading(false);
      props.setProgress(100);
      const observer = new IntersectionObserver(([entry]) =>{
        if (entry.isIntersecting){
          entry.target.classList.remove('hidden');
          entry.target.classList.add('show');
        }
        else{
          entry.target.classList.remove('show');
          entry.target.classList.add('hidden');
        }
      });
      // observer.observe(myRef.current);
    };

    loadData();
    
    
  }, [state]);

  document.body.style.backgroundImage = "linear-gradient(to bottom, black, rgba(117, 19, 93, 0.73))";

  if (loading) {
    return (
      <div id="mainWindowMainFrame" className="container">
        
        <div className="center-screen">
        
          <TailSpin
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      </div>
    );
  } else {
    return (
      
      <div id="mainWindowMainFrame">
        <h1 id="mainWindowHeading">{heading}</h1>
        <div className="container row">
          {games.map((data) => (
            <div
              id="gameCard"
              key={data.id}
              className="card col-md-3 px-2 zoom"
            >
              
              <div>
                <Link
                  to={"/games/" + data.slug}
                  style={{ textDecoration: "none"}}
                  
                >
                  
                  <img id="gameCardImage" src={data.background_image}/>
                  <h5 id="gameCardTitle" className="card-title">
                    {data.name}
                  </h5>
                  <p>{data.description}</p>
                  
                </Link>

                </div>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
